<template lang="pug">
  div
    v-row.my-2
      v-col(cols="1")
        v-btn(
          color="primary"
          dark
          dense
          block
          @click="$router.push('/vouchers/create')"
        )
          span new
      v-col(cols="1")
        export-excel(
          :cost-centers="costCenterIndexList"
          :revenue-sources="revenueSourceIndexList"
        )
    v-data-table(
      :items="voucherList"
      :headers="headers"
      single-expand
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :server-items-length="voucherGetCount"
      :loading="voucherGetting"
      :sort-by.sync="sortBy"
      dense
      :sort-desc.sync="sortDesc"
    )
      template(#body.prepend)
        tr(v-if="showTableTop")
          td(:colspan="headers.length")
            v-row
              v-col(lg="3" cols="12").my-1
                v-text-field(
                  placeholder="Voucher Details Keywords..."
                  append-icon="mdi-magnify"
                  hide-details
                  outlined
                  dense
                  :clearable="voucherDetailKeyword"
                  v-model="voucherDetailKeyword"
                )
      template(#header.id)
        span.mr-1 Voucher No
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-icon(
              v-on="on"
              v-bind="attrs"
            ) mdi-filter
          template(#content)
            base-search-combobox(
              v-model="voucherNoFilter"
            )
        v-icon(
          :color="showTableTop && 'purple'"
          @click="showTableTop = !showTableTop"
        ) mdi-magnify
      template(#header.date)
        span.mr-1 Date
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-icon(
              v-on="on"
              v-bind="attrs"
            ) mdi-filter
          template(#content)
            v-row(no-gutters)
              v-col(cols="12" lg="6" md="6")
                v-text-field(
                  label="From"
                  dense
                  hide-details
                  type="date"
                  v-model="dateFrom"
                )
              v-col(cols="12" lg="6" md="6")
                v-text-field(
                  label="To"
                  dense
                  hide-details
                  type="date"
                  v-model="dateTo"
                )
      template(#header.created_by)
        span.mr-1 Requestor
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-btn(
              text
              small
              v-on="on"
              v-bind="attrs"
            )
              v-icon mdi-filter
          template(#content)
            base-search-combobox(
              v-model="requestorFilter"
            )
      template(#header.pay_to)
        span.mr-1 Pay to
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-btn(
              text
              small
              v-on="on"
              v-bind="attrs"
            )
              v-icon mdi-filter
          template(#content)
            base-search-combobox(
              v-model="payToFilter"
            )
      template(#header.approved_rejected_by)
        span.mr-1 Approve / Reject
        base-tooltip(
          text
          x-small
          :color="showSelfApprovedRejected ? 'purple' : 'blue-grey darken-2'"
          :tooltip="showSelfApprovedRejected ? 'Show everything' : 'Show only your aproved or rejected'"
          bottom
          @click="showSelfApprovedRejected = !showSelfApprovedRejected"
        )
          v-icon mdi-filter
      template(#header.paid_by)
        span.mr-1 Paid by
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-btn(
              text
              small
              v-on="on"
              v-bind="attrs"
            )
              v-icon mdi-filter
          template(#content)
            v-autocomplete(
              label="search"
              append-icon="mdi-magnify"
              multiple
              small-chips
              v-model="paidByFilter"
              :items="revenueSourceIndexList"
              item-value="id"
              item-text="code"
              clearable
              :filter="autocompleteFilter"
            )
              template(#item="{ item }")
                span.mr-2.primary--text {{ item.code }}
                small.blue-grey--text {{ item.description }}
      template(#header.cost_center_id)
        span.mr-1 Cost Center
        base-menu-popover-component(
          color="blue-grey darken-3"
          title="Filter"
          icon="mdi-filter"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        )
          template(#trigger="{ on, attrs }")
            v-btn(
              text
              small
              v-on="on"
              v-bind="attrs"
            )
              v-icon mdi-filter
          template(#content)
            v-autocomplete(
              label="search"
              append-icon="mdi-magnify"
              multiple
              small-chips
              v-model="costCenterFilter"
              :items="costCenterIndexList"
              item-value="id"
              item-text="code"
              clearable
              :filter="autocompleteFilter"
            )
              template(#item="{ item }")
                span.mr-2.primary--text {{ item.code }}
                small.blue-grey--text {{ item.description }}
      template(#item="{ item, expand, isExpanded }")
        tr
          td
            v-btn(
              color="info"
              x-small
              dense
              text
              @click="openInfoDialog(item)"
            )
              v-icon mdi-information-outline
            span {{ item.voucher_no }}
          td {{ item.cost_center.code }}
          td {{ item.pay_to.name }}
          td {{ item.created_by ? item.created_by.name : null }}
          td {{ item.date.dateFormat() }}
          td {{ item.paid_by.code }}
          td.text-uppercase(
            :class="approvedRejectedColor(item.status)"
          ) {{ item.status.description }}
          td.text-right {{ paidAmount(item.transactions).phpFormat() }}
          td.text-center
            v-btn(
              v-if="!item.approved_rejected_by && allowedForApproval"
              color="success"
              text
              small
              dense
              @click="approveVoucher(item.id)"
              :loading="approveVoucherPosting"
            )
              span Approve
            v-btn(
              v-if="!item.approved_rejected_by && allowedForApproval"
              color="error"
              text
              small
              dense
              @click="rejectVoucher(item.id)"
              :loading="rejectVoucherPosting"
            )
              span Reject
            span(
              v-else
              :class="approvedRejectedColor(item.status)"
            ) {{ item.approved_rejected_by ? item.approved_rejected_by.name : null }}
          td.text-right
            base-tooltip(
              v-if="'AP' === item.voucher_status_id"
              bottom
              color="green"
              no-btn
              tooltip="COMPLETE VOUCHER"
            )
              template(v-slot="{ on, loading, isLoading }")
                v-icon(
                  v-on="on"
                  color="green"
                  @click="completeVoucher(item.id, loading, isLoading)"
                ) mdi-check
            v-tooltip(
              v-if="['NW', 'RJ'].includes(item.voucher_status_id)"
              bottom
              color="yellow darken-2"
            )
              template(#activator="{ on, attrs }")
                v-icon(
                  v-on="on"
                  color="yellow darken-2"
                  @click="$router.push({ name: 'vouchers.edit', params: { voucherId: item.id } })"
                ) mdi-pencil
              span edit
            v-tooltip(
              v-if="item.approved_rejected_by && item.voucher_status_id !== 'RJ'"
              color="primary"
              bottom
              dark
            )
              template(#activator="{ on }")
                v-icon(
                  v-on="on"
                  color="primary"
                  @click="openVoucherChequeDialog(item)"
                ) mdi-checkbook
              span Transactions
            base-tooltip(
              v-if="!['NW', 'RJ'].includes(item.voucher_status_id)"
              color="red"
              dark
              bottom
              tooltip="Print PDF"
              no-btn
            )
              template(v-slot="{ on, loading, isLoading }")
                v-icon(
                  v-on="on"
                  color="red"
                  @click="printPdf(item.id, loading, isLoading)"
                ) mdi-note-text
            v-tooltip(
              v-if="item.approved_rejected_by && item.voucher_status_id !== 'RJ'"
              bottom
              color="green"
              dark
            )
              template(#activator="{ on }")
                v-icon(
                  v-on="on"
                  color="green"
                  @click="$router.push({ name: 'vouchers.petty-cash.manage', params: { voucherId: item.id } })"
                ) mdi-note-text
              span Petty Cash Voucher
            v-icon(
              :class="isExpanded ? 'is-expanded' : ''"
              color="blue-grey darken-3"
              @click="expand(!isExpanded)"
            ) mdi-chevron-down
      template(#expanded-item="{ item }")
        tr
          td(colspan="6")
            v-toolbar(
              color="purple"
              dark
              dense
              flat
            )
              span Details
            v-simple-table(dense)
              template(#default)
                thead
                  tr
                    th(width="100").text-center Acct
                    th.text-left Desc.
                    th Reason
                    th.text-right Amnt
                tbody
                  tr(v-for="item in item.details" :key="item.id")
                    td.text-center {{ item.account_code.account_code }}
                    td.text-left(v-html="hightLightKeyword(item.account_code.description)")
                    td(v-html="hightLightKeyword(item.reason)")
                    td.text-right {{ (item.amount || 0).phpFormat() }}
                  tr
                    th(colspan="3").text-left Total
                    td.text-right {{ totalAmount(item.details).phpFormat() }}
          td(colspan="4")
            v-toolbar(
              color="green"
              dark
              dense
              flat
            )
              span Acct Codes
            v-simple-table(dense)
              template(#default)
                thead
                  tr
                    th Acct
                    th.text-right Dis. Amnt
                    th.text-right Amnt
                tbody
                  tr(v-for="item in item.account_codes" :key="item.id")
                    td {{ item.account_code.account_code }}
                    td.text-right {{ (item.disbursed_amount || 0).phpFormat() }}
                    td.text-right {{ item.amount.phpFormat() }}
                  tr(v-if="item.revolving_fund")
                    td --.--
                    td.text-right {{ (item.revolving_fund.disbursed_amount || 0).phpFormat() }}
                    td
                  tr
                    th Total
                    td.text-right
                      span(
                        :class="totalAmount(item.account_codes) < totalDisbursedAmount(item.account_codes, item.revolving_fund) ? 'red--text' : ''"
                      ) {{ totalDisbursedAmount(item.account_codes, item.revolving_fund).phpFormat() }}
                    td.text-right {{ totalAmount(item.account_codes).phpFormat() }}
    voucher-transaction(
      :voucher="voucher"
      v-model="voucherChequeDialog"
    )
    voucher-info(
      v-model="showInfo"
      :data="voucher"
      :approving="approveVoucherPosting"
      :rejecting="rejectVoucherPosting"
      @approve="approveVoucher"
      @reject="rejectVoucher"
    )
</template>
<style lang="sass" scoped>
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import costCenterRepository from '@/repositories/cost-center.repository'
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherGetVars, voucherGetVarNames] = requestVars({ identifier: 'voucher', pagination: true })
const [approveVoucherVars, approveVoucherVarNames] = requestVars({ identifier: 'approve-voucher', hasData: false, request: 'post' })
const [rejectVoucherVars, rejectVoucherVarNames] = requestVars({ identifier: 'reject-voucher', hasData: false, request: 'post' })
const [voucherPrintPdfVars, voucherPrintPdfVarNames] = requestVars({ identifier: 'voucher-print-pdf', hasData: false, request: 'get' })
const [costCenterIndexVars, costCenterIndexVarnames] = requestVars({ identifier: 'cost-center-index' })
const [revenueSourceIndexVars, revenueSourceIndexVarNames] = requestVars({ identifier: 'revenue-source-index' })
const [voucherCompleteVars, voucherCompleteVarNames] = requestVars({ identifier: 'voucher-complete', hasData: false, hasLoading: false, request: 'post' })

const voucherGetHandler = new VueRequestHandler(null, voucherGetVarNames, {}, true)
const approveVoucherHandler = new VueRequestHandler(null, approveVoucherVarNames)
const rejectVoucherHandler = new VueRequestHandler(null, rejectVoucherVarNames)
const voucherPrintPdfHandler = new VueRequestHandler(null, voucherPrintPdfVarNames)
const costCenterIndexHandler = new VueRequestHandler(null, costCenterIndexVarnames)
const revenueSourceIndexHandler = new VueRequestHandler(null, revenueSourceIndexVarNames)
const voucherCompleteHandler = new VueRequestHandler(null, voucherCompleteVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Voucher No.', value: 'id', divider: true, sortable: false },
    { text: 'Cost Center', value: 'cost_center_id', divider: true, sortable: false },
    { text: 'Pay To', value: 'pay_to', divider: true, sortable: false },
    { text: 'Requestor', value: 'created_by', divider: true, sortable: false },
    { text: 'Date', value: 'date', divider: true, sortable: false },
    { text: 'Paid By', value: 'paid_by', divider: true, sortable: false },
    { text: 'Status', value: 'voucher_status_id', divider: true },
    { text: 'Paid Amnt', sortable: false, divider: true },
    { text: 'Approved / Rejected By', value: 'approved_rejected_by', divider: true, sortable: false },
    { text: 'Config', sortable: false },
  ],
  page: 1,
  itemsPerPage: 10,
  sortBy: '',
  sortDesc: false,
  voucherId: null,
  voucherChequeDialog: false,
  showInfo: false,
  showSelfApprovedRejected: false,
  voucherNoFilter: [],
  payToMenu: false,
  payToFilter: [],
  costCenterFilter: [],
  paidByFilter: [],
  requestorFilter: [],
  dateFrom: null,
  dateTo: Window.getCurrentDate(),
  showTableTop: false,
  voucherDetailKeyword: null,
})

export default {
  name: 'TableVoucher',
  components: {
    voucherTransaction: () => import('./transactions/Index'),
    voucherInfo: () => import('./Show'),
    exportExcel: () => import('./ExportExcel'),
  },
  data() {
    return {
      ...tableVars(),
      ...voucherGetVars,
      ...approveVoucherVars,
      ...rejectVoucherVars,
      ...voucherPrintPdfVars,
      ...costCenterIndexVars,
      ...revenueSourceIndexVars,
      ...voucherCompleteVars,
    }
  },
  created() {
    this.getVouchers()
    this.initWebsocket()
    this.getCostCenters()
    this.getRevenueSources()
  },
  computed: {
    voucher() {
      if (!this.voucherId) return {}
      return this.voucherList.find(item => item.id === this.voucherId)
    },
    allowedForApproval() {
      return this.$store.state.auth.accessLevel > 5
    },
    getVoucherIndexParams() {
      const { page, itemsPerPage } = this
      return {
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        voucherNo: this.voucherNoFilter.join(','),
        payTo: this.payToFilter.join(','),
        costCenter: this.costCenterFilter.join(','),
        paidBy: this.paidByFilter.join(','),
        requestor: this.requestorFilter.join(','),
        vdKeywords: this.voucherDetailKeyword,
        sar: this.showSelfApprovedRejected,
      }
    },
  },
  watch: {
    voucherNoFilter() {
      this.page = 1
    },
    getVoucherIndexParams() {
      this.getVouchers()
    },
    voucherDetailKeyword() {
      this.page = 1
    },
  },
  methods: {
    async printPdf(voucherId, loading, isLoading) {
      if (isLoading) return
      loading(true)
      const handler = voucherPrintPdfHandler
      const repository = voucherRepository.printPdf
      handler.setVue(this)
      try {
        const { data } = await handler.execute(repository, [voucherId])
        const blob = new Blob([data.decodeBase64()], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '__blank'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        alert(e.message)
      } finally {
        loading(false)
      }
    },
    openInfoDialog(item) {
      this.voucherId = item.id
      this.showInfo = true
    },
    openVoucherChequeDialog(item) {
      this.voucherId = item.id
      this.voucherChequeDialog = true
    },
    rejectVoucher(id) {
      this.$confirm('Are you sure you want to reject this voucher?', {
        title: 'Reject',
        color: 'error',
        icon: 'mdi-alert',
      }).then(result => {
        if (!result) return
        const handler = rejectVoucherHandler
        const repository = voucherRepository.reject
        handler.setVue(this)
        handler.execute(repository, [id])
      })
    },
    initWebsocket() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbUpdateEvent({ model, data }) {
      if (!model && this.$objectEmpty(data)) return
      if (model === 'CostCenter') {
        this.costCenterIndexList.push(data)
        return
      }
      if (model === 'RevenueSource') {
        this.revenueSourceIndexList.push(data)
        return
      }
      if (model === 'Voucher') {
        this.voucherList = this.voucherList.map(item => {
          if (data.id === item.id) {
            return { ...item, ...data }
          }
          return { ...item }
        })
      }
    },
    approveVoucher(id) {
      this.$confirm('Are you sure you want to approve this voucher ?', { title: 'Approve', color: 'success', icon: 'mdi-check' })
        .then(confirm => {
          if (!confirm) return
          const handler = approveVoucherHandler
          const repository = voucherRepository.approve
          handler.setVue(this)
          handler.execute(repository, [id])
        })
    },
    getVouchers() {
      const handler = voucherGetHandler
      const repository = voucherRepository.index
      const params = this.getVoucherIndexParams
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    convertToVoucherNo({ id }) {
      const voucherNo = id.toString().padStart(7, '0')
      return `V${voucherNo}`
    },
    approvedRejectedColor(args) {
      const { id = null } = args || {}
      if (id === 'AP') return 'green--text'
      if (id === 'RJ') return 'red--text'
      return null
    },
    totalAmount(items) {
      return items.reduce((total, item) => total + item.amount, 0)
    },
    totalBalance(items) {
      return items.reduce((total, item) => item.balance + total, 0)
    },
    totalDisbursedAmount(items, revFund) {
      const disbursedAmount = items.reduce((total, item) => Number(item.disbursed_amount) + total, 0)
      return revFund ? revFund.disbursed_amount + disbursedAmount : disbursedAmount
    },
    getCostCenters() {
      const handler = costCenterIndexHandler
      const repository = costCenterRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getRevenueSources() {
      const handler = revenueSourceIndexHandler
      const repository = revenueSourceRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    autocompleteFilter(item, queryText) {
      const regEx = new RegExp(queryText, 'i')
      return regEx.test(item.code) || regEx.test(item.description)
    },
    paidAmount(transactions) {
      return transactions.reduce((total, item) => total + item.amount, 0)
    },
    async completeVoucher(id, loading, isLoading) {
      const confirm = await this.$confirm('are you sure you want to complete this voucher?')
      if (!confirm) return
      if (isLoading) return
      loading(true)
      const handler = voucherCompleteHandler
      const repository = voucherRepository.complete
      handler.setVue(this)
      try {
        const result = await handler.execute(repository, [id])
      } catch (e) {
        alert(e.message)
      } finally {
        loading(false)
      }
    },
    hightLightKeyword(reason) {
      const keyword = this.voucherDetailKeyword
      
      if (!keyword) return reason
      const regex = new RegExp(keyword, 'ig')
      const result = reason.replace(regex, word => `<span style="background:#FFEB3B;"><strong>${word}</strong></span>`)
      
      return result
    },
  },
  mixins: [
    require('@/views/admin/vouchers/account-codes/mixin').default,
  ],
}
</script>
